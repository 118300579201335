<template>
    <div>
        <div class="upload-container">
            <span :id="id + '_trigger'" :class="'btn btn-' + (btnclass ? btnclass : 'success') + ' upload-trigger'">
                <i :class="btnicon ? btnicon : 'fas fa-plus'"></i>
                <span class="font-weight-bolder">{{btntext ? btntext : 'Aggiungi file...'}}</span>
            </span>
            <input :id="name + '_fileupload'" type="file" :name="name + '_files[]'" class="upload-zone">
        </div>

        <div class="clearfix"></div>
        <div v-if="(progress > 0) && (progress < 100)" class="progress" :id="name + '_progress'" style="height: 30px; line-height: 30px; font-size: 15px;">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" :style="'height: 30px; line-height: 30px; font-size: 15px; width: ' + progress + '%;'"><span v-if="progress">{{progress}} %</span></div>
        </div>

        <div :id="name + '_files'" class="files" style="clear: both; margin-top: 10px;">
            <p v-for="file in files">
                <span class="btn btn-danger" @click="deleteFile(file)">Elimina</span> <strong><a :href="appendToken(file.url)" target="_blank">{{file.name}} [{{getReadableFileSizeString(file.size)}}]</a></strong>
            </p>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import $ from "jquery";
import "blueimp-file-upload/js/jquery.fileupload.js";

export default {
    name: 'FileUpload',

    props: ['id', 'name', 'multiple', 'value', 'btnclass', 'btnicon', 'btntext'],

    data() {
        return {
            progress: 0,

            files: [],

            errors: [],
        };
    },

    created() {
        this.updateValue(this.value);
    },

    mounted() {
        let vm = this;
        let selector = this.$el;

        var url = ApiService.getApiUrl() + '/upload?name=' + this.name + '&token=' + JwtService.getToken();

        $(document).ready(function (){
            $(document).bind('drop dragover', function (e) {
                e.preventDefault();
            });

            $(selector).fileupload({
                dropZone: $(this),
                url: url,
                dataType: 'json',
                done: function (e, data)
                {
                    var results = data.result[vm.name + '_files'];

                    for (var filename in results) {
                        if (results[filename])
                        {
                            if (!vm.multiple)
                            {
                                vm.files = [];
                            }

                            vm.files.push(results[filename]);
                        }
                    }

                    vm.updateFiles();

                    vm.$bvToast.toast('File caricato correttamente.', {
                        title: 'File caricato correttamente',
                        variant: 'success',
                        solid: true
                    });
                },

                progressall: function (e, data)
                {
                    vm.progress = parseInt(data.loaded / data.total * 100, 10);
                },

                add: function (e, data) {
                    vm.progress = 0;

                    if (data.autoUpload || (data.autoUpload !== false && $(this).fileupload('option', 'autoUpload')))
                    {
                        data.process().done(function () {
                            data.submit();
                        });
                    }
                }
            }).prop('disabled', !$.support.fileInput)
            .parent().addClass($.support.fileInput ? undefined : 'disabled');
        });
    },

    watch: {
        'value': {
            handler: function (newValue) {
                this.updateValue(newValue);
            },
            deep: true,
        },
    },

    methods: {
        updateValue(newValue) {
            if (!this.multiple)
            {
                if (newValue)
                {
                    this.files = [newValue];
                }
                else
                {
                    this.files = [];
                }
            }
            else
            {
                this.files = newValue;
            }

            if (this.files === null || typeof this.files === "undefined") this.files = [];

            this.updateFiles();
        },

        updateFiles: function ()
        {
            if (this.multiple)
            {
                this.$emit('input', this.files);
            }
            else
            {
                if (this.files.length)
                {
                    this.$emit('input', this.files[0]);
                }
                else
                {
                    this.$emit('input', '');
                }
            }
        },

        getReadableFileSizeString: function (fileSizeInBytes)
        {
            var i = -1;
            var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
            do {
                fileSizeInBytes = fileSizeInBytes / 1024;
                i++;
            } while (fileSizeInBytes > 1024);

            return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
        },

        removeFile: function (filename)
        {
            for (var i in this.files)
            {
                if (this.files[i].name == filename)
                {
                    this.files.splice(i, 1);
                    this.updateFiles();
                }
            }
        },

        deleteFile: function (file)
        {
            this.progress = 0;

            let vm = this;
            ApiService.query(this.appendToken(file.deleteUrl))
                .then((response) => {
                    if (response.data.ok)
                    {
                        for (var filename in response.data.files)
                        {
                            if (response.data.files[filename])
                            {
                                vm.removeFile(filename);
                            }
                        }

                        vm.$bvToast.toast('File eliminato correttamente.', {
                            title: 'File eliminato correttamente',
                            variant: 'success',
                            solid: true
                        });
                    }
                })
                .catch((error) => {
                    vm.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione del file.', {
                        title: 'Si sono verificati degli errori nell\'eliminazione del file',
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        appendToken(url) {
            let parts = url.split('?');
            if (parts.length >= 2) {
                return url += '&token=' + JwtService.getToken();
            }
            else
        {
                return url += '?token=' + JwtService.getToken();
            }
        },
    },

    destroyed: function ()
    {
        $(this.$el).fileupload('destroy');
    }
};
</script>

<style>
.upload-container {
    position: relative;
    height: 50px;
    overflow: hidden;
    cursor: pointer;
}

.upload-trigger {
    float: left;
    height: 50px;
    display: block;
    position: relative;
    top: 0px;
    left: 0;
    z-index: 1;
    width: 300px;
    cursor: pointer;
}

.upload-zone {
    clear: both;
    float: left;
    height: 50px;
    display: block;
    position: relative;
    top: -50px;
    left: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
    width: 300px;
}
</style>
